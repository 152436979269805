<template>
	<div class="pui-form">
		<resourcerequest-modals :modelName="modelName"></resourcerequest-modals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<pui-field-set :title="$t('form.resourcerequest.basicinfo')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:id="`portid-resourcerequest`"
										:attach="`portname-${modelName}`"
										:label="this.$t('form.resourcerequest.port')"
										toplabel
										clearable
										required
										:disabled="!isCreatingElement || disableForm"
										v-model="model"
										modelName="port"
										:itemsToSelect="[{ id: this.model.portid }]"
										:modelFormMapping="{ id: 'portid' }"
										:key="'portid_' + portidKey"
										itemValue="id"
										itemText="portname"
										reactive
										:fixedFilter="filterByUserPortsAndAuthority"
									></pui-select>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:id="`visitid-${modelName}`"
										:attach="`visitid-${modelName}`"
										:label="$t('form.resourcerequest.visit')"
										toplabel
										clearable
										:disabled="!model.portid || disableForm"
										v-model="model"
										modelName="visit"
										:itemsToSelect="[{ id: this.model.visitid }]"
										:modelFormMapping="{ id: 'visitid' }"
										:itemValue="['id']"
										:itemText="
											(item) =>
												`${item.portcallnumber} - ${item.vesselname} - ${this.$dateTimeUtils.getLocalFormattedDate(
													item.eta,
													'DD/MM/YYYY HH:mm'
												)} - ${this.$dateTimeUtils.getLocalFormattedDate(item.etd, 'DD/MM/YYYY HH:mm')} - ${item.status}`
										"
										:order="{ eta: 'asc' }"
										:fixedFilter="portidFixedFilter"
										:key="'visitid_' + portidKey"
										reactive
										detailComponentName="visit-form"
										detailModelName="visit"
									></pui-select>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:id="`ship-${modelName}`"
										:attach="`ship-${modelName}`"
										:label="$t('visit.vesselname')"
										:disabled="formDisabled || disableForm"
										toplabel
										clearable
										v-model="model"
										singleItemAutoselect
										modelName="ship"
										:itemsToSelect="itemsToSelectShip"
										:modelFormMapping="{ id: 'shipid' }"
										:itemValue="['id']"
										:itemText="(item) => `${item.imo} - ${item.name}`"
										:order="{ name: 'asc' }"
										showOnlyActives
										:fixedFilter="filterShipVersion"
										reactive
									></pui-select>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:id="`stopid-${modelName}`"
										:attach="`stopid-${modelName}`"
										:label="$t('form.resourcerequest.stop')"
										toplabel
										clearable
										:disabled="!model.visitid || disableForm"
										v-model="model"
										:required="model.visitid !== null"
										modelName="stopnodisabled"
										:itemsToSelect="[{ id: this.model.stopid }]"
										:modelFormMapping="{ id: 'stopid' }"
										itemValue="id"
										:itemText="(item) => getStopItemText(item)"
										:order="{ num: 'asc' }"
										:fixedFilter="visitidFixedFilter"
										:key="'stopid_' + visitidKey"
										reactive
										detailComponentName="stop-form"
										detailModelName="stop"
									></pui-select>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6 v-if="$store.getters.isMovementsActives">
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:id="`movementid-${modelName}`"
										:attach="`movementid-${modelName}`"
										:label="$t('form.resourcerequest.movement')"
										toplabel
										clearable
										:disabled="!model.stopid || disableForm"
										v-model="model"
										modelName="movement"
										:itemsToSelect="[{ id: this.model.movementid }]"
										:modelFormMapping="{ id: 'movementid' }"
										itemValue="id"
										:itemText="(item) => getMovementText(item)"
										:order="{ nummovement: 'asc' }"
										:fixedFilter="movementVisitidFixedFilter"
										:key="'movementid_' + visitidKey"
										reactive
										detailComponentName="movement-form"
										detailModelName="movement"
									></pui-select>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:id="`operationid-resourcerequest`"
										v-model="model"
										:label="$t('form.resourcerequest.operation')"
										:disabled="!this.model.stopid || formDisabled || disableForm"
										toplabel
										clearable
										:attach="`operationid-${modelName}`"
										modelName="operation"
										:itemsToSelect="[{ operationid: this.model.operationid }]"
										:modelFormMapping="{ operationid: 'operationid' }"
										itemValue="operationid"
										:itemText="
											(item) =>
												`${item.operationtype} - ${
													item.startdate
														? this.$dateTimeUtils.getLocalFormattedDate(item.startdate, 'DD/MM/YYYY HH:mm')
														: ''
												} - ${
													item.enddate ? this.$dateTimeUtils.getLocalFormattedDate(item.enddate, 'DD/MM/YYYY HH:mm') : ''
												}  - ${item.loadtypename}`
										"
										reactive
										:fixedFilter="operationFixedFilter"
									></pui-select>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md6>
							<v-layout wrap>
								<v-flex xs12>
									<pui-select
										:id="`statusid-resourcerequest`"
										v-model="model"
										:label="$t('form.resourcerequest.status')"
										:disabled="true"
										toplabel
										:attach="`statusid-${modelName}`"
										clearable
										modelName="resourcerequeststatus"
										:itemsToSelect="[{ code: this.model.statusid }]"
										:modelFormMapping="{ code: 'statusid' }"
										itemValue="code"
										itemText="name"
										:order="{ name: 'asc' }"
										reactive
									></pui-select>
								</v-flex>
							</v-layout>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</v-row>
			<v-row class="pui-form-layout">
				<pui-field-set :title="$t('form.resourcerequest.maininfo')">
					<v-row dense>
						<v-col cols="6">
							<pui-select
								:id="`resourcetype-resourcerequest`"
								v-model="model"
								:label="$t('form.resourcerequest.resourcetype')"
								:disabled="!model.portid || formDisabled || disableForm"
								required
								toplabel
								:attach="`resourcetype-${modelName}`"
								clearable
								modelName="resourcetype"
								:itemsToSelect="[{ resourcetypeid: this.model.resourcetype }]"
								:modelFormMapping="{ resourcetypeid: 'resourcetype' }"
								itemValue="resourcetypeid"
								itemText="name"
								:order="{ name: 'asc' }"
								:fixedFilter="resourcetypeFixedFilter"
								reactive
							></pui-select>
						</v-col>
						<v-col cols="4">
							<pui-number-field
								:id="`quantity-resourcerequest`"
								v-model="model.quantity"
								:label="$t('form.resourcerequest.quantity')"
								:disabled="formDisabled || disableForm"
								required
								toplabel
								maxlength="10"
							></pui-number-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="4">
							<pui-date-field
								:id="`startdate-resourcerequest`"
								v-model="model.startdate"
								:label="$t('form.resourcerequest.startdate')"
								:disabled="formDisabled || disableForm"
								toplabel
								required
								:max="model.enddate"
								maxlength="32"
								time
							></pui-date-field>
						</v-col>
						<v-col cols="4">
							<pui-date-field
								:id="`enddate-resourcerequest`"
								v-model="model.enddate"
								:label="$t('form.resourcerequest.enddate')"
								:disabled="formDisabled || disableForm"
								toplabel
								required
								:min="model.startdate"
								maxlength="32"
								time
							></pui-date-field>
						</v-col>
						<v-col cols="4" v-if="!isCreatingElement">
							<pui-date-field
								:id="`requestdate-resourcerequest`"
								v-model="model.requestdate"
								:label="$t('form.resourcerequest.requestdate')"
								:disabled="true"
								required
								toplabel
								maxlength="32"
								time
							></pui-date-field>
						</v-col>
					</v-row>
					<v-row dense>
						<v-col cols="6">
							<pui-text-area
								:id="`comments-resourcerequest`"
								v-model="model.comments"
								:label="$t('form.resourcerequest.comments')"
								:disabled="formDisabled || disableForm"
								toplabel
								maxlength="300"
							></pui-text-area>
						</v-col>
						<v-col cols="6" v-if="model.refusedreason">
							<pui-text-area
								:id="`refusedreason-${modelName}`"
								v-model="model.refusedreason"
								:label="$t('form.resourcerequest.refusedreason')"
								disabled
								toplabel
								maxlength="300"
							></pui-text-area>
						</v-col>
					</v-row>
				</pui-field-set>
			</v-row>
			<custom-fields-generate-form
				:formDisabled="formDisabled"
				:isCreatingElement="isCreatingElement"
				:parentObject="model"
				:parentModelName="modelName"
				:parentPk="pk"
			></custom-fields-generate-form>
			<pui-field-set :title="$t('form.resourcerequest.resourceallocationinfo')" v-if="!isCreatingElement">
				<pui-master-detail
					componentName="resourceallocationgrid"
					:parentModelName="modelName"
					:parentPk="pk"
					:parentPkChildFk="{ id: 'requestid' }"
					:parentModel="model"
					:formDisabled="disableForm"
				></pui-master-detail>
			</pui-field-set>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndUpdate="customSaveAndUpdate"
					:save="customSave"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import resourcerequestActions from './ResourcerequestActions';
import resourcerequestModals from './ResourcerequestModals.vue';

export default {
	name: 'resourcerequest-form',
	mixins: [PuiFormMethodsMixin],
	components: { 'resourcerequest-modals': resourcerequestModals },
	data() {
		return {
			modelName: 'resourcerequest',
			portidKey: 0,
			portidFixedFilter: null,
			visitidKey: 0,
			visitidFixedFilter: null,
			movementVisitidFixedFilter: null,
			visiteta: null,
			visitetd: null,
			userCompanyType: null,
			consigneeCompanyType: 5,
			disableForm: null,
			visitShip: null,
			actions: resourcerequestActions.formactions
		};
	},
	methods: {
		getStopItemText(item) {
			let startdate = item.ata ? item.ata : item.etaauth ? item.etaauth : item.eta;
			let enddate = item.atd ? item.atd : item.etdauth ? item.etdauth : item.etd;

			return `${item.num} - ${this.$dateTimeUtils.getLocalFormattedDate(startdate, 'DD/MM/YYYY HH:mm')}  ${
				enddate ? '-' + this.$dateTimeUtils.getLocalFormattedDate(enddate, 'DD/MM/YYYY HH:mm') : ''
			} - ${item.status} - ${item.location}`;
		},
		afterGetData() {
			// Do something after get data from server
			this.userCompanyType = this.$store.state.session.properties.COMPANY.companytypeid;
			this.model.companyid = this.$store.getters.getCompanyId;
			this.model.portauthorityid = window.localStorage.getItem('workingPortAuthorityId');
			if (this.isCreatingElement) {
				this.model.statusid = 'RQ';
			} else {
				if (this.model.statusid) {
					this.disableForm = this.model.statusid !== 'RQ' ? true : false;
				}
			}
		},
		customSave() {
			this.model.requestdate = new Date();
			this.save();
		},
		customSaveAndUpdate() {
			this.model.requestdate = new Date();
			this.internal.afterInsertGoToUpdate = true;
			this.internal.afterUpdateGoToUpdate = true;
			this.save(false);
		},
		getMovementText(item) {
			return `${item.nummovement} - ${item.movementtype}  ${
				item.starttime ? '-' + this.$dateTimeUtils.getLocalFormattedDate(item.starttime, 'DD/MM/YYYY HH:mm') : ''
			}  ${item.endtime ? '-' + this.$dateTimeUtils.getLocalFormattedDate(item.endtime, 'DD/MM/YYYY HH:mm') : ''}
											- ${item.movementstatus}`;
		}
	},
	computed: {
		filterByUserPortsAndAuthority() {
			const userPorts = this.$store.getters.getPortsIds;
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'id', op: 'in', data: userPorts },
					{ field: 'portauthorityid', op: 'eq', data: this.model.portauthorityid }
				]
			};
		},
		filterShipVersion() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'next_version_id', op: 'nu', data: null },
					{ field: 'type_port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId },
					this.ownlistships
						? { field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }
						: { field: 'port_authority_id', op: 'nu', data: null },
					this.visitShip ? { field: 'id', op: 'eq', data: this.visitShip } : null
				]
			};
		},
		resourcetypeFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'portauthorityid', op: 'eq', data: this.model.portauthorityid }]
			};
		},
		operationFixedFilter() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'visitid', op: 'eq', data: this.model.visitid },
					{ field: 'stopid', op: 'eq', data: this.model.stopid }
				]
			};
		},
		visitStatusCodes() {
			return this.$store.getters.getActiveVisitStatusid;
		},
		movementStatusCodes() {
			let movementStatus = this.$store.getters.getActiveMovementStatusid;
			movementStatus.push('FI');
			return movementStatus;
		},
		filterShipVersion() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'next_version_id', op: 'nu', data: null },
					{ field: 'type_port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId },
					this.ownlistships
						? { field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }
						: { field: 'port_authority_id', op: 'nu', data: null },
					this.visitShip ? { field: 'id', op: 'eq', data: this.visitShip } : null
				]
			};
		},
		itemsToSelectShip() {
			return [{ id: this.model.shipid }];
		}
	},
	created() {
		this.$puiEvents.$on('onPuiSelect_selectedItems-visitid-' + this.modelName, (newVal) => {
			var lupaModel = newVal.model;
			if (lupaModel !== null) {
				this.visitShip = lupaModel.shipid;
				this.model.shipid = lupaModel.shipid;
			} else {
				this.visitShip = null;
				this.model.shipid = null;
			}
		});
	},
	watch: {
		'model.portid'(newVal) {
			if (newVal) {
				const maxFinalizedDays = this.$store.state.session.properties.FINALIZED_VISIT_DAYS;
				if (maxFinalizedDays && maxFinalizedDays > 0) {
					let maxFinishedDate = new Date();
					maxFinishedDate.setDate(maxFinishedDate.getDate() - maxFinalizedDays);
					this.portidFixedFilter = {
						groups: [
							{
								groupOp: 'or',
								rules: [
									{ field: 'statusid', op: 'in', data: this.visitStatusCodes },
									{ field: 'atd', op: 'gt', data: maxFinishedDate }
								]
							}
						],
						groupOp: 'and',
						rules: [{ field: 'portid', op: 'eq', data: newVal }]
					};
				} else {
					this.portidFixedFilter = {
						groups: [],
						groupOp: 'and',
						rules: [
							{ field: 'portid', op: 'eq', data: newVal },
							{ field: 'statusid', op: 'in', data: this.visitStatusCodes }
						]
					};
					if (this.userCompanyType && this.userCompanyType === this.consigneeCompanyType) {
						this.portidFixedFilter = {
							groups: [],
							groupOp: 'and',
							rules: [
								{ field: 'portid', op: 'eq', data: newVal },
								{ field: 'statusid', op: 'in', data: this.visitStatusCodes },
								{ field: 'consigneeid', op: 'eq', data: this.model.companyid }
							]
						};
					}
				}
			} else {
				this.portidFixedFilter = null;
				this.visitidFixedFilter = null;
				this.movementVisitidFixedFilter = null;
				this.model.portid = null;
				this.model.stopid = null;
				this.model.movementid = null;
			}

			this.portidKey += 1;
		},
		'model.visitid'(newVal) {
			if (newVal) {
				this.visitidFixedFilter = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'visitid', op: 'eq', data: newVal }]
				};
			} else {
				this.visitidFixedFilter = null;
				this.model.stopid = null;
				this.model.movementid = null;
			}
			this.visitidKey += 1;
		},
		'model.stopid'(newVal) {
			if (newVal) {
				this.movementVisitidFixedFilter = {
					groupOp: 'and',
					rules: [{ field: 'movementstatuscode', op: 'in', data: this.movementStatusCodes }],
					groups: [
						{
							groupOp: 'or',
							rules: [
								{ field: 'stopfrom', op: 'eq', data: newVal },
								{ field: 'stopto', op: 'eq', data: newVal }
							]
						}
					]
				};
			} else {
				this.movementVisitidFixedFilter = null;
				this.model.movementid = null;
			}
			this.visitidKey += 1;
		}
	},
	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-visitid-' + this.modelName);
	}
};
</script>
